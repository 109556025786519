import {
  BaseStyle,
  DarkTheme,
  GlobalStyle,
  LightTheme,
  Spinner,
  SpinnerContainer,
  ThemeTypes,
  logger,
  themes,
  useCurrenciesContext,
  useIntlContext,
  usePreferredColorScheme,
  useSecuritiesContext,
  useWLCustomerMarketAccountContext,
  useWLCustomerUserConfigContext,
  useWLOrgConfigContext,
  useWLUserContext,
  useWLUsersContext,
} from '@talos/kyoko';
import { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';

export const ProvidersGate = ({ children }) => {
  const { isLoaded: isCurrenciesReady } = useCurrenciesContext();
  const { isLoaded: isSecuritiesReady } = useSecuritiesContext();
  const { isLoaded: isConfigReady } = useWLCustomerUserConfigContext();
  const { isLoaded: isUserReady } = useWLUserContext();
  const { isLoaded: isCustomerConfigReady, config } = useWLOrgConfigContext();
  const { isLoaded: isIntlReady } = useIntlContext();
  const { isLoaded: isUsersLoaded } = useWLUsersContext();
  const { isLoaded: isMarketAccountsLoaded } = useWLCustomerMarketAccountContext();
  const [providersReady, setProvidersReady] = useState(false);
  const [disconnected, setDisconnected] = useState(false);

  const scheme = usePreferredColorScheme();

  useEffect(() => {
    setProvidersReady(
      isCurrenciesReady &&
        isSecuritiesReady &&
        isCustomerConfigReady &&
        isConfigReady &&
        isUserReady &&
        isIntlReady &&
        isUsersLoaded &&
        isMarketAccountsLoaded
    );
  }, [
    isCurrenciesReady,
    isSecuritiesReady,
    isCustomerConfigReady,
    isConfigReady,
    isUserReady,
    isIntlReady,
    isUsersLoaded,
    isMarketAccountsLoaded,
  ]);

  useEffect(() => {
    const ready = providersReady;
    if (ready) {
      logger.info('[pg] Providers are ready');
    }

    const timeout = setTimeout(() => {
      setDisconnected(!ready);
      if (ready) {
        logger.warn('[pg] Providers not ready within timeout');
      }
    }, 2 * 60 * 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [providersReady]);

  const theme = isCustomerConfigReady ? themes[config.theme] : scheme === ThemeTypes.dark ? DarkTheme : LightTheme;

  return providersReady ? (
    children
  ) : (
    <ThemeProvider theme={theme}>
      <BaseStyle />
      <GlobalStyle />
      <SpinnerContainer>
        {disconnected ? <div style={{ placeSelf: 'center', margin: '0 auto' }}>Disconnected!</div> : <Spinner />}
      </SpinnerContainer>
    </ThemeProvider>
  );
};
