import {
  Button,
  ButtonVariants,
  CurrencyIcon,
  DecisionStatusEnum,
  FormattedMessage,
  IconButton,
  IconName,
  InlineFormattedNumber,
  Meter,
  OrdStatusEnum,
  OrderStatus,
  SmartSummary,
  Text,
  WLCanModifyCustomerOrder,
  WLCanResubmitCustomerOrder,
  WL_CANCEL_ORDER,
  WL_INITIATE_ORDER,
  WL_MODIFY_ORDER,
  abbreviateId,
  formattedDate,
  getIntlKey,
  getOrderStatusText,
  getStatusColor,
  logger,
  orderSliceMessages,
  useGetStrategyLikeLabel,
  useOnClickOutside,
  useSecuritiesContext,
  useWLRoleAuth,
  type CustomerOrder,
  type Security,
  type WLOrderStrategy,
} from '@talos/kyoko';
import Big from 'big.js';
import { sortBy } from 'lodash';
import { useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import { ACTIVE_ORDER_STATUSES } from '../../../tokens/order';
import { ExecutionCardWrapper, Footer, Header, Label, ParametersSection, Row, Section, Value } from './styles';

const messages = defineMessages({
  limitPrice: {
    defaultMessage: 'Limit Price',
    id: 'OrderForms.ExecutionCard.limitPrice',
  },
  strategy: {
    defaultMessage: 'Strategy',
    id: 'OrderForms.ExecutionCard.strategy',
  },
  quantity: {
    defaultMessage: 'Quantity',
    id: 'OrderForms.ExecutionCard.quantity',
  },
  averageFillPrice: {
    defaultMessage: 'Average Fill Price',
    id: 'OrderForms.ExecutionCard.averageFillPrice',
  },
  filledQty: {
    defaultMessage: 'Filled Quantity',
    id: 'OrderForms.ExecutionCard.filledQty',
  },
  cancelOrder: {
    defaultMessage: 'Cancel Order',
    id: 'OrderForms.ExecutionCard.cancelOrder',
  },
  modify: {
    defaultMessage: 'Modify',
    id: 'OrderForms.ExecutionCard.modify',
  },
  resubmit: {
    defaultMessage: 'Resubmit',
    id: 'OrderForms.ExecutionCard.resubmit',
  },
  archiveOrder: {
    defaultMessage: 'Archive',
    id: 'OrderForms.ExecutionCard.archiveOrder',
  },
  id: {
    defaultMessage: 'ID',
    id: 'OrderForms.ExecutionCard.id',
  },
});

export const ExecutionCard = ({
  order,
  security,
  strategy,
  onCancelOrder,
  onCloseCard,
  onModifyOrder,
  onArchiveOrder,
  onResubmitOrder,
}: ExecutionCardProps) => {
  const {
    Currency,
    OrdStatus,
    OrderQty,
    CumQty,
    DecisionStatus,
    PricingMode,
    OrderID,
    Text: text,
    Price,
    Symbol,
    Parameters,
    AvgPx,
  } = order;
  const { isAuthorized } = useWLRoleAuth();

  const { securitiesBySymbol } = useSecuritiesContext();

  const displaySymbol = securitiesBySymbol.get(Symbol)?.DisplaySymbol ?? Symbol;

  const status = getOrderStatusText({
    ordStatus: OrdStatus ?? OrdStatusEnum.PendingNew,
    orderQty: OrderQty ?? '',
    cumQty: CumQty ?? '',
    decisionStatus: DecisionStatus ?? DecisionStatusEnum.Active,
    pricingMode: order.PricingMode,
  });
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(wrapperRef, onCloseCard);
  const { BaseCurrency, QuoteCurrency, DefaultSizeIncrement, DefaultPriceIncrement } = security;

  const isCcy = Currency === QuoteCurrency;
  const qtyIncrement = isCcy ? DefaultPriceIncrement : DefaultSizeIncrement;

  const theme = useTheme();
  const { baseSize } = theme;

  const filledShare = Big(OrderQty ?? '0').gt(0) ? Big(CumQty ?? '0').div(OrderQty) : Big(0);

  const progressBarColor = getStatusColor(status, theme);

  const isActive = ACTIVE_ORDER_STATUSES.includes(order.OrdStatus);

  const formattedParametersUnsorted = Object.entries(Parameters ?? {})
    .filter(([key]) => key !== 'ErrorAction')
    .map(([key, v]) => {
      if (typeof v !== 'string' && typeof v !== 'number') {
        return null;
      }
      if (strategy != null) {
        const index = strategy.Parameters.findIndex(param => param.Name === key);
        if (index === -1) {
          // If we can't find any param, something is wrong. Returning undefined makes nothing show.
          logger.error(new Error('Unsupported parameter found on Order'), {
            extra: {
              orderParameters: order.Parameters,
            },
          });
          return undefined;
        }

        const { DisplayName, Type } = strategy.Parameters[index];
        const label = DisplayName;
        const value = (() => {
          switch (Type) {
            case 'Date':
              return formattedDate(Sugar.Date.create(v));
            case 'Price':
              return (
                <InlineFormattedNumber
                  number={v as string}
                  increment={DefaultPriceIncrement}
                  currency={QuoteCurrency}
                />
              );
            case 'Qty':
              return <InlineFormattedNumber number={v as string} increment={qtyIncrement} currency={Currency} />;
            case 'Percent':
              return <InlineFormattedNumber number={Big(v).times(100).toFixed()} increment="0.01" currency="%" />;
            default:
              return v;
          }
        })();

        return { label, value };
      } else {
        return { label: key, value: v };
      }
    })
    .compact();

  const formattedParameters = sortBy(formattedParametersUnsorted, item => item.label);
  const intl = useIntl();

  const getStrategyLikeLabel = useGetStrategyLikeLabel();

  return (
    <ExecutionCardWrapper ref={wrapperRef} status={status} data-testid="order-form-execution-card">
      <IconButton onClick={onCloseCard} icon={IconName.Close} ghost />
      <Header>
        <CurrencyIcon size={baseSize * 1.5} currency={BaseCurrency} />
        {displaySymbol}
      </Header>
      <Section>
        <Row>
          <OrderStatus
            ordStatus={OrdStatus}
            decisionStatus={DecisionStatus}
            cumQty={CumQty}
            orderQty={OrderQty}
            iconPlacement="left"
            pricingMode={PricingMode}
          />
          <Label>
            <FormattedMessage {...messages.id} />
          </Label>
          <Value>{abbreviateId(OrderID)}</Value>
        </Row>
      </Section>
      <Section>
        <Row>
          <Label>
            <FormattedMessage {...messages.quantity} />
          </Label>
          <Value>
            <SmartSummary
              entity={order}
              type="order"
              showSide
              showQtyCurrency
              security={security.Symbol}
              showPrice={false}
            />
          </Value>
        </Row>
        {Price != null && (
          <Row>
            <Label>
              <FormattedMessage {...messages.limitPrice} />
            </Label>
            <Value>
              <InlineFormattedNumber number={Price} increment={DefaultPriceIncrement} currency={QuoteCurrency} />
            </Value>
          </Row>
        )}
        {strategy != null && (
          <Row>
            <Label>
              <FormattedMessage {...messages.strategy} />
            </Label>
            <Value>{getStrategyLikeLabel(strategy)}</Value>
          </Row>
        )}
        {strategy != null && (
          <ParametersSection>
            {formattedParameters.map(({ label, value }) => {
              const translatedLabel =
                getIntlKey(label) in orderSliceMessages
                  ? intl.formatMessage(orderSliceMessages[getIntlKey(label)])
                  : label;
              return (
                <Row key={label}>
                  <Label>{translatedLabel}</Label>
                  <Value>{value}</Value>
                </Row>
              );
            })}
          </ParametersSection>
        )}
      </Section>
      {OrdStatus !== OrdStatusEnum.Rejected && (
        <Section>
          <Row>
            <Label>
              <FormattedMessage {...messages.averageFillPrice} />
            </Label>
            <Value>
              <InlineFormattedNumber number={AvgPx} increment={DefaultPriceIncrement} currency={QuoteCurrency} />
            </Value>
          </Row>
          <Row>
            <Label>
              <FormattedMessage {...messages.filledQty} />
            </Label>
            <Value>
              <InlineFormattedNumber
                number={filledShare.times(100).toFixed()}
                increment="0.01"
                currency="%"
                round={true}
              />{' '}
              / <InlineFormattedNumber number={CumQty} increment={qtyIncrement} currency={Currency} />
            </Value>
          </Row>
          <Row>
            <Meter value={filledShare.toNumber()} color={progressBarColor} showLabel={false} />
          </Row>
        </Section>
      )}
      <Section>
        <Text color="colorTextWarning">{text}</Text>
      </Section>
      <Footer>
        {isActive ? (
          <>
            {isAuthorized(WL_CANCEL_ORDER) && (
              <Button data-testid="cancel-order" onClick={() => onCancelOrder(OrderID)}>
                <FormattedMessage {...messages.cancelOrder} />
              </Button>
            )}
            {isAuthorized(WL_MODIFY_ORDER) && (
              <Button
                data-testid="modify-order"
                onClick={() => onModifyOrder(order)}
                variant={ButtonVariants.Primary}
                disabled={!WLCanModifyCustomerOrder(order)}
              >
                <FormattedMessage {...messages.modify} />
              </Button>
            )}
          </>
        ) : (
          <>
            <Button onClick={() => onArchiveOrder(OrderID)} data-testid="archive-button">
              <FormattedMessage {...messages.archiveOrder} />
            </Button>
            {isAuthorized(WL_INITIATE_ORDER) && (
              <Button
                onClick={() => onResubmitOrder(order)}
                variant={ButtonVariants.Primary}
                disabled={!WLCanResubmitCustomerOrder(order)}
                data-testid="resubmit-button"
              >
                <FormattedMessage {...messages.resubmit} />
              </Button>
            )}
          </>
        )}
      </Footer>
    </ExecutionCardWrapper>
  );
};

type ExecutionCardProps = {
  order: CustomerOrder;
  security: Security;
  strategy?: WLOrderStrategy;
  onCloseCard: () => void;
  onResubmitOrder: (order: CustomerOrder) => void;
  onModifyOrder: (order: CustomerOrder) => void;
  onCancelOrder: (orderId: string) => void;
  onArchiveOrder: (orderId: string) => void;
};
