import type { FilterableProperty } from '@talos/kyoko';
import { IconName, useDynamicCallback, useGetStrategyLikeLabel, useIntl } from '@talos/kyoko';
import { useMemo } from 'react';
import { defineMessages } from 'react-intl';
import { useStrategiesContext } from '../../../../providers';

const messages = defineMessages({
  strategy: {
    defaultMessage: 'Strategy',
    id: 'Filters.FilterBuilder.filters.strategy',
  },
  none: {
    defaultMessage: 'None',
    id: 'Filters.FilterBuilder.filters.none',
  },
});

/**
 * This hook creates the filter property definition for Strategy properties.
 *
 * @param overrides Any overrides to make to the defaults here (e.g. `key`, etc.)
 * @returns Filter Property definition to use for Strategy filters
 */
export function useStrategiesFilter<TKey extends string = 'Strategies', P = FilterableProperty<TKey>>(
  overrides?: { [K in keyof P]: K extends keyof FilterableProperty<TKey> ? P[K] : never } & { key?: TKey }
) {
  const { strategiesByName } = useStrategiesContext();
  const { formatMessage } = useIntl();
  const getStrategyLikeLabel = useGetStrategyLikeLabel();

  const getOptionLabel = useDynamicCallback((option: string) => {
    const strategyObj = strategiesByName.get(option);
    const displayValue = strategyObj ? getStrategyLikeLabel(strategyObj) : undefined;

    if (!displayValue) {
      // No display value found, return the option or None
      return option || formatMessage(messages.none);
    }

    return displayValue;
  });

  return useMemo(
    () => ({
      ...({
        key: 'Strategies' as TKey,
        label: formatMessage(messages.strategy),
        icon: IconName.DocumentText,
        // Include the option to lookup empty strategies
        options: [''].concat(Array.from(strategiesByName?.keys())),
        getOptionLabel,
      } satisfies FilterableProperty<TKey>),
      ...overrides,
    }),
    [formatMessage, strategiesByName, getOptionLabel, overrides]
  );
}
