import Big from 'big.js';
import { useCallback, useMemo } from 'react';
import type { Observable } from 'rxjs';
import { useFlashSentiment, useObservableValue } from '../../hooks';
import { format } from '../../utils';
import { InlineFormattedNumber, StackedFormattedNumber } from '../FormattedNumber';

import { useTheme } from 'styled-components';
import { SideEnum } from '../../types';
import { Direction } from '../../types/Direction';
import type { MarketDataSnapshot } from '../../types/MarketDataSnapshot';
import type { Security } from '../../types/Security';
import { PriceButton, SpreadDisplay, TopOfBookWrapper } from './styles';
import type { ClickRowCallback } from './types';

const DEFAULT_SIZE_MIN_WIDTH = 232;
const NARROW_WIDTH = 160;

export const TopOfBook = ({
  topOfBookObservable,
  security,
  onClickRow,
  showSentiment = true,
  showSpread,
  width,
  bpsIncrement = '0',
  usePreciseTopOfBookIncrement = true,
}: TopOfBookProps) => {
  const { Bids, Offers } = useObservableValue(() => topOfBookObservable, [topOfBookObservable]) ?? {};
  const topBid = Bids?.[0];
  const topOffer = Offers?.[0];
  const { PriceDisplaySpec, DefaultPriceIncrement, MinPriceIncrement } = security ?? {};

  const effectiveIncrement = usePreciseTopOfBookIncrement ? MinPriceIncrement : DefaultPriceIncrement;

  const bidSentiment = useFlashSentiment(security?.Symbol, Direction.Asc, topBid?.Price);
  const offerSentiment = useFlashSentiment(security?.Symbol, Direction.Asc, topOffer?.Price);

  const theme = useTheme();
  const { baseSize, largeSize, pricePadding } = useMemo(() => {
    if (width == null || width > DEFAULT_SIZE_MIN_WIDTH) {
      return {
        baseSize: theme.fontSizeLarge,
        largeSize: 1.75,
        pricePadding: theme.spacingMedium,
      };
    } else if (width > NARROW_WIDTH) {
      return {
        pricePadding: theme.spacingDefault,
        baseSize: theme.fontSizeSmall,
        largeSize: 1.25,
      };
    } else {
      return {
        pricePadding: theme.spacingSmall,
        baseSize: theme.fontSizeTiny,
        largeSize: theme.fontSizeLarge,
      };
    }
  }, [theme, width]);

  const isNarrow = width != null && width < NARROW_WIDTH;

  const handleClickBid = useCallback(
    () =>
      topBid != null &&
      onClickRow({
        side: SideEnum.Sell,
        price: format(topBid.Price, { round: true, pretty: false, spec: effectiveIncrement }),
      }),
    [onClickRow, topBid, effectiveIncrement]
  );
  const handleClickOffer = useCallback(
    () =>
      topOffer != null &&
      onClickRow({
        side: SideEnum.Buy,
        price: format(topOffer.Price, { round: true, pretty: false, spec: effectiveIncrement }),
      }),
    [onClickRow, topOffer, effectiveIncrement]
  );

  if (topOffer == null && topBid == null) {
    return null;
  }

  const spread = topOffer != null && topBid != null ? Big(topOffer.Price).minus(topBid.Price) : undefined;
  const spreadBps =
    spread != null && topOffer != null && topBid != null
      ? spread.div(Big(topOffer.Price).add(topBid.Price).div(2)).times(10_000)
      : undefined;

  return (
    <TopOfBookWrapper>
      <PriceButton data-testid="top-of-book-bid" onClick={handleClickBid} p={pricePadding}>
        <StackedFormattedNumber
          number={topBid?.Price}
          specification={PriceDisplaySpec}
          increment={effectiveIncrement}
          variant={showSentiment ? bidSentiment : undefined}
          round={true}
          largeSize={largeSize}
          baseSize={baseSize}
        />
      </PriceButton>
      <PriceButton data-testid="top-of-book-offer" onClick={handleClickOffer} p={pricePadding}>
        <StackedFormattedNumber
          number={topOffer?.Price}
          specification={PriceDisplaySpec}
          increment={effectiveIncrement}
          variant={showSentiment ? offerSentiment : undefined}
          round={true}
          largeSize={largeSize}
          baseSize={baseSize}
        />
      </PriceButton>
      <SpreadDisplay data-testid="top-of-book-spread-display">
        {showSpread && !isNarrow && (
          <InlineFormattedNumber number={spreadBps?.toFixed()} increment={bpsIncrement} currency="BPS" round={true} />
        )}
      </SpreadDisplay>
    </TopOfBookWrapper>
  );
};

export type TopOfBookProps = {
  security?: Security;
  topOfBookObservable: Observable<MarketDataSnapshot>;
  onClickRow: ClickRowCallback;
  showSentiment?: boolean;
  showSpread?: boolean;
  width?: number;
  bpsIncrement?: string;
  usePreciseTopOfBookIncrement?: boolean;
};
