import {
  Box,
  ButtonVariants,
  Expiration,
  FormControlSizes,
  IconButton,
  IconName,
  Input,
  MarketDataCardHeader as MarketDataCardHeaderComponent,
  MixpanelEvent,
  MixpanelEventProperty,
  SymbolDisplay,
  SymbolSelector,
  SymbolSelectorAppearance,
  Tab,
  TabAppearance,
  Tabs,
  VolumeLadderMarketView,
  WL_USE_SYMBOL_SELECTOR,
  isCFD,
  primeOrderForm,
  useHistoricalPrices,
  useIntl,
  useMarketDataSnapshot,
  useMixpanel,
  useWLOrderFormDispatch,
  useWLOrgConfigContext,
  useWLRoleAuth,
  type VolumeLadderMarketViewProps,
} from '@talos/kyoko';
import { noop } from 'lodash';
import { useCallback, useState } from 'react';

import { DragHandle, FloatingDescription } from './styles';

import { TabList, TabSize } from '@talos/kyoko';
import Big from 'big.js';
import { defineMessages } from 'react-intl';
import type { MarketDataCardHeaderProps } from './types';

const messages = defineMessages({
  oneMinAbbr: {
    defaultMessage: '1M',
    id: 'MarketDataCard.oneMinAbbr',
  },
  fiveMinAbbr: {
    defaultMessage: '5M',
    id: 'MarketDataCard.fiveMinAbbr',
  },
});

const RESOLUTIONS = ['1M', '5M'];

export const MarketDataCardHeader = ({
  config,
  draggableAttributes,
  draggableListeners,
  isDraggable,
  onDelete,
  onSaveCard,
  security,
}: MarketDataCardHeaderProps) => {
  const mixpanel = useMixpanel();
  const { isAuthorized } = useWLRoleAuth();
  const { config: customerConfig } = useWLOrgConfigContext();
  const { resolution = '1M', topOfBookSizeBucket } = config;
  const [qty, setQty] = useState(topOfBookSizeBucket ?? security?.MinPriceIncrement);
  const { formatMessage } = useIntl();

  const { marketDataSnapshots: marketViewObservable } = useMarketDataSnapshot({
    clearOnSymbolChange: true,
    quantity: topOfBookSizeBucket,
    symbol: security!.Symbol,
    tag: `SECURITY_CARD_TOB`,
    priceIncrement: security?.MinPriceIncrement,
    includeTradeDirection: false,
  });

  const sparklineDataObservable = useHistoricalPrices({
    clearOnSymbolChange: true,
    symbol: security!.Symbol,
    resolution,
  });

  const orderFormDispatch = useWLOrderFormDispatch();

  const handleClickRow = useCallback(
    ({ price, side, size }) => {
      mixpanel.track(MixpanelEvent.ClickLadderRow, { [MixpanelEventProperty.Side]: side });
      orderFormDispatch(
        primeOrderForm({
          Symbol: security!.Symbol,
          Side: side,
          Price: price,
          OrderQty: size != null ? Big(size).toFixed() : qty,
        })
      );
    },
    [orderFormDispatch, mixpanel, qty, security]
  ) satisfies VolumeLadderMarketViewProps['onClickRow'];

  const handleChangeResolution = useCallback(
    (resolutionIndex: number) => {
      onSaveCard({
        ...config,
        resolution: RESOLUTIONS[resolutionIndex],
      });
    },
    [config, onSaveCard]
  );

  const handleChangeSymbol = useCallback(
    (symbol?: string) => {
      if (symbol) {
        onSaveCard({
          ...config,
          symbol,
        });
      }
    },
    [config, onSaveCard]
  );

  const handleTOBSizeBucketBlur = useCallback(() => {
    if (qty !== config.topOfBookSizeBucket && !isNaN(qty as any)) {
      onSaveCard({
        ...config,
        topOfBookSizeBucket: qty,
      });
    }
  }, [qty, config, onSaveCard]);
  const handleTOBSizeBucketKeyPress = useCallback(
    e => {
      if (e.key.toLowerCase() === 'enter') {
        handleTOBSizeBucketBlur();
      }
    },
    [handleTOBSizeBucketBlur]
  );
  const handleUpdateTOBSizeBucket = useCallback(
    e => {
      setQty(e.target.value);
    },
    [setQty]
  );

  const disabledSymbolSelector = !isAuthorized(WL_USE_SYMBOL_SELECTOR);

  const topCenterItems = (
    <>
      {disabledSymbolSelector ? (
        <SymbolDisplay symbol={security!.Symbol} />
      ) : (
        <SymbolSelector
          appearance={SymbolSelectorAppearance.Ghost}
          symbol={security!.Symbol}
          onSymbolChanged={handleChangeSymbol}
          dropdownWidth="200px"
          disabled={disabledSymbolSelector}
          portalize={true}
          showSelectedSymbolDescription={false}
        />
      )}
      {isCFD(security) && (
        <FloatingDescription data-testid="market-data-card-header-floating-description">
          <Expiration>CFD</Expiration>
        </FloatingDescription>
      )}
    </>
  );

  const topLeftItems = (
    <Tabs
      selectedIndex={RESOLUTIONS.findIndex(res => res === resolution)}
      onSelect={handleChangeResolution}
      appearance={TabAppearance.Pill}
      size={TabSize.Small}
    >
      <TabList suppressOverflowList={true}>
        <Tab label={formatMessage(messages.oneMinAbbr)} />
        <Tab label={formatMessage(messages.fiveMinAbbr)} />
      </TabList>
    </Tabs>
  );
  const topRightItems = (
    <>
      <IconButton
        className="delete"
        icon={IconName.Trash}
        ghost
        variant={ButtonVariants.Negative}
        onClick={() => onDelete(config)}
        size={FormControlSizes.Small}
      />
      {isDraggable && (
        <DragHandle
          icon={IconName.Reorder}
          ghost
          variant={ButtonVariants.Priority}
          onClick={noop}
          size={FormControlSizes.Small}
          {...draggableAttributes}
          {...draggableListeners}
        />
      )}
    </>
  );

  const bottomCenterItems = (
    <Box w="33%">
      <Input
        value={qty}
        onChange={handleUpdateTOBSizeBucket}
        onKeyUp={handleTOBSizeBucketKeyPress}
        onBlur={handleTOBSizeBucketBlur}
        controlStyle={{ textAlign: 'center' }}
        data-testid="market-data-card-top-of-book-input"
        size={FormControlSizes.Small}
      />
    </Box>
  );

  return (
    <MarketDataCardHeaderComponent
      security={security}
      slotFlexRule="0 0 68px"
      bottomCenterItems={bottomCenterItems}
      topCenterItems={topCenterItems}
      topLeftItems={topLeftItems}
      topRightItems={topRightItems}
    >
      <VolumeLadderMarketView
        resolution={resolution}
        onClickRow={handleClickRow}
        marketDataObservable={marketViewObservable}
        sparklineDataObservable={sparklineDataObservable}
        showSentiment={false}
        showSpread={customerConfig.showSpreadOnVolumeLadder}
        showReferenceLines={false}
        bpsIncrement={customerConfig.volumeLadderDecimalPrecision}
        security={security}
        usePreciseTopOfBookIncrement={customerConfig.usePreciseTopOfBookIncrement}
      />
    </MarketDataCardHeaderComponent>
  );
};
