import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { initAgGrid, initBigJs, initDatadog, initSentry, initWhyDidYouRender } from '@talos/kyoko/src/init';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { enableMapSet } from 'immer';
import { isNil } from 'lodash';
import { SparklinesText } from 'react-sparklines';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './utils/sugar';

const environment = import.meta.env.VITE_AVA_ENV;
const revision = import.meta.env.VITE_GIT_HASH;
const version = import.meta.env.VITE_NPM_PACKAGE_VERSION;

initWhyDidYouRender(environment);
initSentry({ environment, dsn: import.meta.env.VITE_SENTRY_DSN });
initDatadog({
  environment,
  version: environment === 'prod' ? version : revision,
  applicationId: import.meta.env.VITE_DATADOG_APP_ID,
  clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  proxy: import.meta.env.VITE_DATADOG_PROXY_URL,
  service: 'whitelabel-ui',
});
initAgGrid();
initBigJs();
enableMapSet();

const rootElement = document.getElementById('root');
if (isNil(rootElement)) {
  throw Error('Could not find root element to render app into');
}
const root = createRoot(rootElement);
root.render(
  <StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (import.meta.env.VITE_AVA_ENV === 'local') {
  // ok, since it's expected to be removed in production
  // eslint-disable-next-line react/forbid-foreign-prop-types
  delete SparklinesText.propTypes.text;
}
